import React, {Component} from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Core/Seo"
import CoreContainer from "../components/Core/CoreContainer"
import styled from "styled-components"
import fonts from "../styles/fonts"
import breakpoints from "../styles/breakpoints"

const StyledContainer = styled.div`
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Text = styled.h1`
  ${fonts.nhassGroteskRoman}
  font-size: 8.7px;
  @media(min-width: ${breakpoints.md}) {
    font-size: 11px;
  }
`

class NotFoundPage extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Page not found."
             description="The page you are looking for has been removed or relocated."/>
             <CoreContainer>
               <StyledContainer>
                <Text>The page you are looking for has been removed or relocated.</Text>
               </StyledContainer>
             </CoreContainer>
      </Layout>
    );
  }
}

export default NotFoundPage;
